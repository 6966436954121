// src/components/Hero.js
import React from 'react';
import './Hero.css';
import { useLanguage } from '../context/LanguageContext';

function Hero() {
  const { language, setLanguage } = useLanguage();

  const content = {
    en: {
      subtitle: <><span className="green">Green and</span><span className='dynamic'> Dynamic Softwares</span></>,
      button: "Learn More"
    },
    tr: {
      subtitle: <><span className="green">Çevreci ve</span><span className='dynamic'> Dinamik Yazılımlar</span></>,
      button: "Daha Fazla"
    }
  };

  const handleLearnMore = (e) => {
    e.preventDefault();
    const aboutSection = document.getElementById('about');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
    window.history.pushState({}, '', window.location.pathname);
  };

  return (
    <section className="hero">
      <div className="language-selector">
        <button 
          className={`lang-btn ${language === 'tr' ? 'active' : ''}`}
          onClick={() => setLanguage('tr')}
        >
          TR
        </button>
        <span className="lang-divider">|</span>
        <button 
          className={`lang-btn ${language === 'en' ? 'active' : ''}`}
          onClick={() => setLanguage('en')}
        >
          EN
        </button>
      </div>
      <div className="hero-image"></div>
      <div className="hero-text">
        <h1><span className="eco">Eco</span><span className="fluxion">Fluxion</span></h1>
        <p>{content[language].subtitle}</p>
        <button 
          onClick={handleLearnMore} 
          className="hero-btn"
        >
          {content[language].button}
        </button>
      </div>
    </section>
  );
}

export default Hero;