// src/components/About.js
import React from 'react';
import './About.css';
import { useLanguage } from '../context/LanguageContext';

function About() {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "About Us",
      intro: <>EcoFluxion was founded with a commitment to environmental sustainability 
             and innovation. Our name blends <span className="highlight">Eco</span> for 
             ecology and <span className="highlight">Fluxion</span> for continuous change, 
             reflecting our focus on dynamic environmental processes and sustainable solutions.</>,
      mission: "We develop technologies that support ecosystem preservation and help minimize environmental impacts. Through advanced data analysis and custom software, we empower clients to reduce their footprint and achieve their sustainability goals.",
      vision: "EcoFluxion is dedicated to building a greener, more sustainable future by promoting eco-friendly solutions across industries."
    },
    tr: {
        title: "Hakkımızda",
        intro: <>EcoFluxion, çevresel sürdürülebilirlik ve yenilikçilik konusundaki güçlü taahhüdümüzle doğdu.
               Adımız, doğanın enerjisini simgeleyen <span className="highlight">Eco</span> ile değişimin kaçınılmaz 
               gücünü temsil eden <span className="highlight">Fluxion</span> kelimelerinin birleşiminden gelmektedir. 
               Bu isim, dünyamızın sürdürülebilir geleceğine katkı sunma arzumuzu ve çevreye duyarlı çözümler üretme 
               kararlılığımızı yansıtmaktadır.</>,
        mission: "Çevresel etkileri minimize eden, doğayı korumayı öncelik haline getiren teknolojiler geliştiriyoruz. İleri veri analitiği ve özel yazılım çözümlerimizle, müşterilerimizin karbon ayak izlerini azaltmalarına, sürdürülebilirlik hedeflerine güvenle ulaşmalarına destek sağlıyoruz.",
        vision: "EcoFluxion olarak, sürdürülebilirlik ilkelerini her sektörde yaygınlaştırarak, daha yeşil, daha yaşanabilir bir dünya inşa etmeye kendimizi adıyoruz."
    }
      
  };

  return (
    <section id="about" className="about">
      <div className="about-container">
        <h2 className="about-title">{content[language].title}</h2>
        <div className="about-content">
          <div className="about-text">
            <p className="about-intro">{content[language].intro}</p>
            <p className="about-mission">{content[language].mission}</p>
            <p className="about-vision">{content[language].vision}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;