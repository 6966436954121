// src/components/Contact.js
import React, { useState, useEffect } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';
import { useLanguage } from '../context/LanguageContext';

function Contact() {
  const [status, setStatus] = useState('');
  const { language } = useLanguage();

  const content = {
    en: {
      getInTouch: "Get in Touch",
      contactUs: "Contact Us",
      name: "Name",
      email: "Email",
      message: "Message",
      sendMessage: "Send Message",
      sending: "Sending...",
      successMessage: "Message sent successfully!",
      errorMessage: "Failed to send message. Please try again."
    },
    tr: {
      getInTouch: "İletişim Bilgileri",
      contactUs: "İletişim Formu",
      name: "İsim",
      email: "E-posta",
      message: "Mesaj",
      sendMessage: "Gönder",
      sending: "Gönderiliyor...",
      successMessage: "Mesajınız başarıyla gönderildi!",
      errorMessage: "Mesaj gönderilemedi. Lütfen tekrar deneyin."
    }
  };

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      const timer = setTimeout(() => {
        setStatus('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      await emailjs.sendForm(
        'service_edy5xsr',
        'template_7ljowda',
        e.target,
        'Rgls7ESRGx6OXlE3l'
      );
      setStatus('success');
      e.target.reset();
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <section id="contact" className="contact">
      <div className="contact-container">
        <div className="company-info">
          <h2>{content[language].getInTouch}</h2>
          <div className="info-item">
            <i className="fas fa-user"></i>
            <p>İsmail Tarik Şenkal</p>
          </div>
          <div className="info-item">
            <i className="fas fa-envelope"></i>
            <p>itarik.senkal@ecofluxion.com</p>
          </div>
          <div className="info-item">
            <i className="fas fa-phone"></i>
            <p>+90 (542) 412 21 11</p>
          </div>
          <div className="info-item">
            <i className="fas fa-map-marker-alt"></i>
            <p>Çankaya / Ankara</p>
          </div>
        </div>
        
        <div className="contact-form">
          <h2>{content[language].contactUs}</h2>
          <form onSubmit={handleSubmit}>
            <input 
              type="text" 
              name="from_name" 
              placeholder={content[language].name} 
              required 
            />
            <input 
              type="email" 
              name="from_email" 
              placeholder={content[language].email} 
              required 
            />
            <textarea 
              name="message" 
              placeholder={content[language].message} 
              required 
            />
            <button type="submit" disabled={status === 'sending'}>
              {status === 'sending' ? content[language].sending : content[language].sendMessage}
            </button>
            {status === 'success' && (
              <div className="message success">
                {content[language].successMessage}
              </div>
            )}
            {status === 'error' && (
              <div className="message error">
                {content[language].errorMessage}
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;