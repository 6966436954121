// src/App.js
import React from 'react';
import './App.css';
import Hero from './components/Hero';
import About from './components/About';
import Contact from './components/Contact';
import { LanguageProvider } from './context/LanguageContext';

function App() {

  return (
    <LanguageProvider>
      <div className="App">
        <Hero />
        <About />
        <Contact />
      </div>
    </LanguageProvider>
  );
}

export default App;
